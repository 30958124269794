
const platforms = [
    {
        name: 'gtag',
        method: (eventName, params) => sendGtagEvent(eventName, params)
    },
    // {
    //     name: 'mixpanel',
    //     method: (eventName, params) => sendMixpanelEvent(eventName, params)
    // },
];

const actionElements = ['button', 'a', 'input', 'select'];

window._sendEvent = sendEvent;

document.addEventListener('click',

    function(e){ 
        if(e.target){
    
            const element = e.target
            const elementTag = element.tagName.toLowerCase();
    
            if(element.getAttribute('data-trackable-event')){
                triggerTrackableElementEvent(element)
            } else if(element.parentElement.getAttribute('data-trackable-event')){
                triggerTrackableElementEvent(element.parentElement)
            } else if(actionElements.includes(elementTag)){
                triggerActionElementEvent(element)
            } else if(element.parentElement && actionElements.includes(element.parentElement.tagName.toLowerCase())){
                triggerActionElementEvent(element.parentElement)
            }
    
        }
    }
);

function getElementInfo(element) {

    const elementTag = element.tagName.toLowerCase();
    let param = 'NN'; // NN is the fallback value

    try{
        param =  element.getAttribute('data-trackable-action-name') || //data-trackable-action-name has the priority above all
                (['button', 'a'].includes(elementTag) ? element.textContent.trim() : false) ||  //if it's a button or an anchor get the text
                (elementTag == 'input' && ['checkbox', 'radio'].includes(element.type) ? `${element.name} - ${element.value}`: false) || //if it's checkbox or a radio, get the element name + the value selected
                (element.id && !element.id.includes('__BVID__')? element.id : false) || //get the ID if it's not a BOOTSTRAPVUE generated ID
                element.name || //get the element name
                element.href ||
                element.src ||
                param; //fallback !! MUST BE THE LAST CONDITION !!
    } catch(e){
        console.log('GET ELEMENT INFO ERROR', e);
    }

    return { param, elementTag };

}

function triggerActionElementEvent(element) {

    const info = getElementInfo(element);

    sendEvent('click', {
        'action': info.param,
        'origin_page': document.title,
        'element_tag': info.elementTag
    })

}

function triggerTrackableElementEvent(element) {

    const eventTracked = element.getAttribute('data-trackable-event');
    const info = getElementInfo(element);

    sendEvent(eventTracked, {
        'action': info.param,
        'origin_page': document.title,
        'element_tag': info.elementTag
    })

}

function sendEvent(eventName, params){
    platforms.forEach( platform => {
        platform.method(eventName, params);
    })
}

function sendGtagEvent(eventName, params = {}){

    var dataObject = {
        'event': 'analytics_event',
        'analytics_event_name': eventName,
    };
    for (var attrname in params) { dataObject[attrname] = params[attrname]; }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataObject);
    
}

function sendMixpanelEvent(eventName, params){
    mixpanel.track(eventName, params)
}